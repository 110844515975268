import tillages from '@constants/tillages';
import MapService from '@services/mapService/mapService';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import isArrayEqual from '@utils/isArrayEqual';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FieldsTillageDataT } from '../SelectStepTypes';

import { SelectFieldStepPropsT } from './SelectFieldStepTypes';
import SelectFieldStepView from './SelectFieldStepView';
import { FieldSuggestionTypeT } from '@services/onboardingApiService/onboardingApiServiceTypes';
import useFieldSuggestion from '@pages/HomePage/Installation/hooks/useFieldSuggestion';
import { useCanEditFarmSeason } from '@hooks/usePermissions';

const tillageSuggestionMap: { [id: number]: FieldSuggestionTypeT } = {
    1: 'tillage/conventional',
    2: 'tillage/reduced',
    3: 'tillage/no-till',
    4: 'tillage/no-tillage',
};

const SelectFieldStep = ({
    selectedTillage,
    formFields,
    setFormFields,
    popStep,
}: SelectFieldStepPropsT): JSX.Element => {
    const { t } = useTranslation();
    // get map state
    const mapState = useSelector((state: HomePageStateT) => state.map);
    const thisMap = MapService.getMapFromState({ state: mapState });
    const [selectedFields, setSelectedFields] = useState<FieldsTillageDataT[]>([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    const [permanentFieldIds, setPermanentFieldIds] = useState<number[]>([]);
    const addPermanentFieldId = (fieldIdToAdd: number) => setPermanentFieldIds((values) => [...values, fieldIdToAdd]);
    const removePermanentFieldId = (fieldIdToRemove: number) =>
        setPermanentFieldIds((values) => [...values.filter((fid) => fid !== fieldIdToRemove)]);

    const tillageSuggestionController = useFieldSuggestion({
        suggestionType: tillageSuggestionMap[selectedTillage.id],
    });

    useEffect(() => {
        if (!canEditFarmSeason.access) {
            return () => MapService.updateSelectedPolygons({ selectedPolygons: [] });
        }

        MapService.selectablePolygons({ selectMode: true });
        MapService.defineCondition({
            selectCondition: ({ id: polygonId }) => {
                const field = formFields.find((formField) => formField.id === polygonId);

                return {
                    selectType: field?.tillage_id ? 'warning' : null,
                    modal: {
                        title: t(
                            'pages.installationtasks.fields-subtasks.tillages-task.select-step.select-field-step.alert-selection-model.title',
                        ),
                        description: `${t(
                            'pages.installationtasks.fields-subtasks.tillages-task.select-step.select-field-step.alert-selection-model.description',
                        )} ${t(tillages.find((tillage) => tillage.id === field?.tillage_id)?.name || '')}`,
                        confirmMessage: t(
                            'pages.installationtasks.fields-subtasks.tillages-task.select-step.select-field-step.alert-selection-model.confirm-message',
                        ),
                    },
                };
            },
        });

        return () => {
            MapService.selectablePolygons({ selectMode: false });
            MapService.defineCondition({ selectCondition: null });
        };
    }, []);

    useEffect(() => {
        // We know formFields is initialized before component mount
        const formSelectedFields = formFields.filter((field) => field.tillage_id === selectedTillage.id);

        const selectedAndPermanentFieldIds = formSelectedFields
            .filter((field) => field.is_tillage_permanent)
            .map((field) => field.id);
        setPermanentFieldIds(selectedAndPermanentFieldIds);

        // only select polygons if necessary?
        if (thisMap?.selectedPolygons?.length !== formSelectedFields?.length) {
            MapService.updateSelectedPolygons({
                selectedPolygons: formSelectedFields.map((field) => field.polygon),
            });
        }
    }, []);

    useEffect(() => {
        const selected = formFields.filter((field) =>
            thisMap.selectedPolygons?.some((polygon) => polygon.id === field.polygon.id),
        );

        setSelectedFields(selected);
    }, [thisMap.selectedPolygons]);

    const onSelectedFieldDelete = (id: number | string) => {
        // remove polygon from the map will update selected fields
        MapService.updateSelectedPolygons({
            selectedPolygons: thisMap.selectedPolygons?.filter((polygon) => polygon.id !== id) || [],
        });
    };

    const onConfirm = () => {
        setFormFields((values) => ({
            fields: values.fields.map((field) => {
                const newField = { ...field };
                const selectedField = selectedFields.find((sField) => sField.id === field.id);

                // Delete this tillage from all
                if (newField.tillage_id === selectedTillage.id) {
                    newField.tillage_id = null;
                    newField.is_tillage_permanent = null;
                }

                // Change tillage id
                if (selectedField) {
                    newField.tillage_id = selectedTillage.id;

                    // Attribute is_tillage_permanent for checked fields
                    const isFieldPermanent = !!permanentFieldIds.find((fid) => newField.id === fid);
                    newField.is_tillage_permanent = isFieldPermanent;
                }

                return newField;
            }),
        }));
        setIsSubmitted(true);
        popStep();
    };

    const onSelectAll = () => {
        const fieldsWithoutTillage = formFields.filter(
            (formField) =>
                !selectedFields.find((field) => field.id === formField.id) &&
                (!formField.tillage_id || formField.tillage_id === selectedTillage.id) &&
                !Boolean(formField.field_crops?.find((field_crop) => field_crop.farm_season_crop.crop.is_permanent)),
        );
        setSelectedFields([...selectedFields, ...fieldsWithoutTillage]);
        MapService.updateSelectedPolygons({
            selectedPolygons: [
                ...(thisMap.selectedPolygons || []),
                ...fieldsWithoutTillage.map((field) => field.polygon),
            ],
        });
    };

    const updateFieldsPermanentTillage = useCallback(
        (fieldId: number, isPermanent: boolean) => {
            const fieldIndex = permanentFieldIds.findIndex((fid) => fid === fieldId);
            const fieldInPermanentFieldIds = fieldIndex >= 0;
            if (isPermanent && !fieldInPermanentFieldIds) {
                addPermanentFieldId(fieldId);
            }

            if (!isPermanent && fieldInPermanentFieldIds) {
                removePermanentFieldId(fieldId);
            }
        },
        [permanentFieldIds],
    );

    const updateAllFieldsPermanentTillage = useCallback(() => {
        if (selectedFields.length === permanentFieldIds.length) {
            setPermanentFieldIds([]);
        } else {
            setPermanentFieldIds([...selectedFields.map((el) => el.id)]);
        }
    }, [permanentFieldIds, selectedFields]);

    return (
        <SelectFieldStepView
            isDirty={
                !isArrayEqual(
                    selectedFields,
                    formFields?.filter((formField) => formField.id === selectedTillage.id),
                )
            }
            isSubmitted={isSubmitted}
            selectedTillage={selectedTillage}
            t={t}
            onConfirm={onConfirm}
            onSelectAll={onSelectAll}
            onSelectedFieldDelete={onSelectedFieldDelete}
            selectedFields={selectedFields}
            formFields={formFields}
            updateFieldsPermanentTillage={updateFieldsPermanentTillage}
            updateAllFieldsPermanentTillage={updateAllFieldsPermanentTillage}
            tillageSuggestionController={tillageSuggestionController}
            permanentFieldIds={permanentFieldIds}
        />
    );
};

export default SelectFieldStep;
