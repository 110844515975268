import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import { Checkbox, Typography } from '@mui/material';
import { Prompt } from '@components/Prompt/Prompt';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import useSelectFieldStepStyle from './SelectFieldStepView.style';
import { SelectFieldStepViewPropsT } from './SelectFieldStepTypes';
import SelectedFieldsContainer from '@components/SelectedFieldsContainer/SelectedFieldsContainer';
import { useSelector } from 'react-redux';
import selectIsYoySeason from '@store/selectors/selectIsYoySeason';
import CallToActionSuggestion from '@pages/HomePage/Installation/components/CallToActionSuggestion/CallToActionSuggestion';
import TooltipSc from '@components/TooltipSc/TooltipSc';
import FieldAmountLabel from '@components/FieldsAmountLabelDeprecated/FieldAmountLabelDeprecated';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import RestrictSaveFarmSeason from '@components/RestrictSaveFarmSeason/RestrictSaveFarmSeason';
import LoadingButton from '@components/LoadingButton/LoadingButton';

const SelectFieldStepView = ({
    selectedTillage,
    onSelectedFieldDelete,
    t,
    onConfirm,
    onSelectAll,
    isSubmitted,
    isDirty,
    selectedFields,
    updateFieldsPermanentTillage,
    updateAllFieldsPermanentTillage,
    formFields,
    permanentFieldIds,
    tillageSuggestionController: { handleBringBackSuggestion, needAttentionFields, suggestedFields, suggestionDone },
}: SelectFieldStepViewPropsT): JSX.Element => {
    const { classes } = useSelectFieldStepStyle();
    const isYoySeason = useSelector(selectIsYoySeason());
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    return (
        <div className={classes.container}>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={!isSubmitted && isDirty}
            />
            <Typography className={classes.title} color="secondary" variant="h4">
                {t('pages.installationtasks.fields-subtasks.tillages-task.select-step.select-field-step.title')} :&nbsp;
                <span className={classes.titleDarker}>{t(selectedTillage?.name)}</span>
            </Typography>
            {canEditFarmSeason.access && isYoySeason && (
                <CallToActionSuggestion
                    onClick={handleBringBackSuggestion}
                    suggestion={{
                        done: suggestionDone,
                        needAttentionCount: needAttentionFields.length,
                        suggestedCount: suggestedFields.length,
                    }}
                />
            )}
            <SelectedFieldsContainer
                disabled={!canEditFarmSeason.access}
                needAttentionFields={needAttentionFields}
                getAdditionalActions={(fieldId) => [
                    <Checkbox
                        disabled={!canEditFarmSeason.access}
                        key="checkbox-action"
                        className={classes.checkbox}
                        disableRipple
                        onChange={(v) => updateFieldsPermanentTillage(fieldId, v.target.checked)}
                        checked={!!permanentFieldIds.find((fid) => fid === fieldId)}
                    />,
                ]}
                fieldsListOverride={formFields}
                suggestedFields={suggestedFields}
                headerText={
                    <div>
                        <FieldAmountLabel fields={selectedFields} />
                        <div className={classes.selectAll}>
                            <Checkbox
                                checked={permanentFieldIds.length === selectedFields.length}
                                className={classes.selectAllCheckBox}
                                onClick={updateAllFieldsPermanentTillage}
                            />
                            <Typography color="primary" variant="h6">
                                {permanentFieldIds.length === selectedFields.length
                                    ? t(
                                          'pages.installationtasks.fields-subtasks.tillages-task.select-step.select-field-step.unselect-all',
                                      )
                                    : t(
                                          'pages.installationtasks.fields-subtasks.tillages-task.select-step.select-field-step.select-all',
                                      )}
                            </Typography>
                        </div>
                    </div>
                }
                handleRemoveSelectedPolygon={onSelectedFieldDelete}
                subHeader={
                    selectedFields.length > 0 ? (
                        <TooltipSc translationSlug={'tooltip.label-is-permanent-tillage'} display="block">
                            <div className={classes.checkboxTitleContainer}>
                                <Typography color="primary" variant="h6" lineHeight="20px">
                                    {t(
                                        'pages.installationtasks.fields-subtasks.tillages-task.select-step.select-field-step.label-is-permanent-tillage',
                                    )}
                                </Typography>
                                <SubdirectoryArrowLeftIcon className={classes.arrowDownIcon} color="primary" />
                            </div>
                        </TooltipSc>
                    ) : null
                }
            />
            <TwoButtonFooterView
                leftOnClick={onSelectAll}
                leftText={canEditFarmSeason.access ? t('constants.select-remaining-fields') : ''}
                rightButtonOverride={
                    <RestrictSaveFarmSeason>
                        <LoadingButton
                            loading={false}
                            onClick={onConfirm}
                            text={canEditFarmSeason.access ? t('constants.confirm') : t('constants.save')}
                        />
                    </RestrictSaveFarmSeason>
                }
            />
        </div>
    );
};

export default SelectFieldStepView;
